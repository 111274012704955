import _ from "lodash";
import { forceUpdate } from "@stencil/core";
import { mapStateToQueryParam } from "./mo-utils";
/* For setting up addons during component initialization. */
export const setupAddOns = (component, options = {}) => {
    // For deep-linking by binding component state to URL params.
    if (options.deepLinking && component.deepLinkingStates) {
        // For example, @Prop() deepLinkingStates = { query: 'q' }
        // will sync component prop `query` with query param `q`.
        _.each(component.deepLinkingStates, (urlParam, componentState) => {
            mapStateToQueryParam(component, componentState, urlParam);
        });
    }
};
export const forceUpdateElement = (element) => {
    if (element) {
        forceUpdate(element);
    }
};
